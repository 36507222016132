<template>
    <input
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target.value === '' ? null : parseInt($event.target.value)))"
        :required="required"
        :disabled="disabled"
        placeholder="YYYYMM"
        type="number"
        step="1"
        min="200001"
        max="209912"
        class="form-control text-center"
    >
</template>

<script>
export default {
    name: 'FormInputMonth',
    props: {
        modelValue: {
            type: Number
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue',
        'validlyChange',
    ],
    watch: {
        modelValue(after, before) {
            if (after === '' || after === null) {
                return;
            }
            if (after < 100000) {
                return;
            }
            if (after >= 1000000) {
                this.$emit('update:modelValue', before);
                this.$emit('validlyChange');
                return;
            }

            let year = Math.ceil(after / 100);
            let month = after % 100;
            if (month === 0) {
                year = year - 1;
                month = 12;
                this.$emit('update:modelValue', year * 100 + month);
            } else if (month === 13) {
                year = year + 1;
                month = 1;
                this.$emit('update:modelValue', year * 100 + month);
            } else if (month > 13) {
                this.$emit('update:modelValue', before);
            }
            this.$emit('validlyChange');
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
